@mixin clearfix {
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    zoom: 1;
}
@supports (-webkit-line-clamp: 2) {
    .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        max-height:2.6em; /* I needed this to get it to work */
        height: auto;
    }
    .line-clamp:after {
        display: none;
    }
}
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
@mixin hcenter {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
@mixin vcenter {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
}
@mixin absolutecenter {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}
@mixin fullwidth{
    position:absolute;
    left:0;
    right:0;
}
@mixin linked-layer($sprite, $position: relative) {
    @include sprite-width($sprite);
    @include sprite-height($sprite);
    position: $position;
    a {
        display: block;
        width: 100%;
        height: 100%;
        @include sprite-image($sprite);
        @include sprite-position($sprite);
        font-size: 0;
    }
}

@mixin linked-layer-retina($sprite-group, $position: relative) {
    display: block;
    @include retina-sprite($sprite-group);
    position: $position;
    a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 0;
    }
}

@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin user-select($val) {
    -webkit-touch-callout: $val;
    -webkit-user-select: $val;
    -khtml-user-select: $val;
    -moz-user-select: $val;
    -ms-user-select: $val;
    user-select: $val;
}

@mixin pseudo-layer($sprite, $top: 0px, $left: 0px, $pseudo: before) {
    position: relative;
    &:#{$pseudo} {
        @include sprite($sprite);
        content: " ";
        display: block;
        position: absolute;
        top: $top;
        left: $left;
    }
}

@mixin pseudo-layer-retina($sprite-group, $top: 0px, $left: 0px, $pseudo: before) {
    position: relative;
    &:#{$pseudo} {
        @include retina-sprite($sprite-group);
        content: " ";
        display: block;
        position: absolute;
        top: $top;
        left: $left;
    }
}