$color-alert : #E81A57;
$color-success: #46C386;

%message {

	margin: 20px 0 20px 0;
	padding: 10px;
	color: #FFF;

	ul {

		li {
			padding: 5px 10px 5px 10px;
		}

	}

}


.alert {

	@extend %message;
	background-color: $color-alert;

}
.success {

	@extend %message;
	background-color: $color-success;

}