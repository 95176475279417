$color-menubg: $color-textlink;
$color-active-menubg: $color-textlink-hover;

ul.menu {

	width: 100%;
	height: auto;
	background-color: $color-menubg;

	li {

		display: inline-block;
		@extend %size-normal;

		a {
			color: #FFF;
			text-decoration: none;
			display: block;
			padding: 10px;
			&:hover,&.active {
				background-color: $color-active-menubg;
			}
		}

	}

}