body {
	min-width: 1040px;
}

.page {
	padding: 20px;
}
.cols {
	width: 100%;
	@extend %clearfix;
	margin-bottom: $base-line-height;
	.col {
		width: 500px;
		float: left;
		&:nth-child(1) {

			padding-right:  $base-line-height;

		}
		&:nth-child(2) {

			padding-left:  $base-line-height;

		}
		table {
			width: 100%;
		}
	}
}