// COLORS
$color-background: #EBEBEB;

$color-topbar: #7C7C7C;
$color-topbar-active-background: #D2D2D2;
$color-topbar-text: #EBEBEB;
$color-topbar-active-text: #7C7C7C;

$color-searchbox: #53CBDE;
$color-sidebar: #F5F5F5;

$color-text: #616161;
$color-textlink: #0065a8;
$color-textlink-hover: #008783;

$color-light-gray: #B0B0B0;
$color-dark-gray: #878787;
$color-lightest-gray: #DCDCDC;

$search-box-width: 722px;

// BORDERS

$border-radius: 8px;

// FONTS

$font-primary: "Fira Sans", "Lato", sans-serif;
$font-secondary: "Fira Sans", "Lato", sans-serif;

// ANIMATIONS

$animation-speed-fast: 0.2s;
$animation-speed-slow: 0.4s;

$wrapper-width: 1240px;

