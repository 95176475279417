@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700italic,700,400italic&subset=latin,latin-ext);
.powertable .pagination, .powertable .list_top, .powertable .list_header, .cols {
  zoom: 1; }
  .powertable .pagination:before, .powertable .list_top:before, .powertable .list_header:before, .cols:before, .powertable .pagination:after, .powertable .list_top:after, .powertable .list_header:after, .cols:after {
    content: "";
    display: table; }
  .powertable .pagination:after, .powertable .list_top:after, .powertable .list_header:after, .cols:after {
    clear: both; }

.powertable {
  margin-bottom: 20px; }
  .powertable .pagination {
    width: 100%;
    font-size: 13px;
    text-transform: lowercase;
    font-weight: 300;
    margin: 16px 0 0 0; }
    .powertable .pagination li a {
      display: block;
      text-transform: lowercase; }
    .powertable .pagination li a[rel="next"] {
      float: right; }
      .powertable .pagination li a[rel="next"]:after {
        content: " »"; }
    .powertable .pagination li a[rel="prev"] {
      float: left; }
      .powertable .pagination li a[rel="prev"]:before {
        content: "« "; }
  .powertable .list_header {
    width: 100%; }
    .powertable .list_header h1 {
      line-height: 45px;
      width: 100%; }
    .powertable .list_header .pages {
      display: block;
      width: 50%;
      float: left; }
    .powertable .list_header .table_menu {
      display: block;
      width: 50%;
      float: right; }
      .powertable .list_header .table_menu li {
        float: right;
        display: block;
        margin: 0 0 0 10px; }
  .powertable table.maintable {
    width: 100%;
    margin: 16px 0 0 0; }
  .powertable tbody {
    font-size: 13px;
    line-height: 15px; }
    .powertable tbody p {
      margin: 0 0 0 0;
      font-weight: 300; }
    .powertable tbody .cellid {
      position: absolute;
      top: 2px;
      left: 0px;
      display: block; }
    .powertable tbody .celldata {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0 0 0 20px; }
    .powertable tbody strong {
      font-weight: 500; }
    .powertable tbody i {
      font-weight: 400;
      font-style: italic; }
    .powertable tbody p {
      line-height: 14px; }
    .powertable tbody .datalist p {
      font-size: 11px;
      line-height: 14px;
      margin: 0 0 0 0; }
    .powertable tbody td {
      padding: 6px;
      font-weight: 300; }
    .powertable tbody th {
      padding: 6px;
      text-align: left;
      font-weight: 700; }
    .powertable tbody tr {
      border-bottom: 1px dashed #FFF; }
      .powertable tbody tr:last-child {
        border-bottom: none; }
      .powertable tbody tr:hover {
        background-color: #f7f7f7; }
  .powertable thead, .powertable tfoot {
    font-size: 13px;
    background-color: #f7f7f7;
    font-weight: 500;
    margin: 0 0 20px 0;
    text-align: left; }
    .powertable thead tr th, .powertable tfoot tr th {
      padding: 10px 13px 10px 6px; }
      .powertable thead tr th a, .powertable tfoot tr th a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  position: relative;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
  -webkit-font-variant-ligatures: common-ligatures;
  /* for iOS and Safari 6 */
  font-variant-ligatures: common-ligatures;
  /* for up-to-date browsers, including IE10 and Opera 21 */
  text-rendering: optimizeLegibility;
  /* optional: for older browsers */
  -moz-font-feature-settings: "kern=1";
  /* pre-Firefox 14+ */
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  /* Firefox 14+ */
  font-feature-settings: "kern";
  /* standard */
  font-kerning: normal;
  /* Safari 7+, Firefox 24+, Chrome 33(?)+, Opera 21+*/ }

a:hover {
  cursor: pointer; }

a:focus {
  outline: none; }

img,
object,
embed {
  max-width: 100%;
  height: auto; }

object,
embed {
  height: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

img {
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px; }

select {
  width: 100%; }

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  outline: none; }

ul, li, ol {
  list-style-type: none; }

.hidden {
  display: none !important; }

strong {
  font-weight: bold; }

input[type="text"] {
  -webkit-appearance: none;
  border-radius: 0; }

@supports (-webkit-line-clamp: 2) {
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.6em;
    /* I needed this to get it to work */
    height: auto; }
  .line-clamp:after {
    display: none; } }

.field .twofield .fieldname, .field li label {
  font-size: 12px;
  line-height: 15px; }

ul.menu li {
  font-size: 14px;
  line-height: 18px; }

*  {
  font-family: "Open Sans", Helvetica, Arial; }

h1 {
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 33px; }

a {
  color: #0065a8; }
  a:hover {
    color: #008783; }

ul.menu {
  width: 100%;
  height: auto;
  background-color: #0065a8; }
  ul.menu li {
    display: inline-block; }
    ul.menu li a {
      color: #FFF;
      text-decoration: none;
      display: block;
      padding: 10px; }
      ul.menu li a:hover, ul.menu li a.active {
        background-color: #008783; }

body {
  min-width: 1040px; }

.page {
  padding: 20px; }

.cols {
  width: 100%;
  margin-bottom: 16px; }
  .cols .col {
    width: 500px;
    float: left; }
    .cols .col:nth-child(1) {
      padding-right: 16px; }
    .cols .col:nth-child(2) {
      padding-left: 16px; }
    .cols .col table {
      width: 100%; }

form input[type=submit] {
  height: 23px;
  padding: 4px;
  width: auto;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer; }

form input[type=submit] {
  border: 1px solid #0065a8;
  color: #0065a8; }
  form input[type=submit]:hover {
    background-color: #0065a8;
    color: #FFF; }

form input[type=submit].delete {
  border: 1px solid #E81A57;
  color: #E81A57; }
  form input[type=submit].delete:hover {
    background-color: #E81A57;
    color: #FFF; }

form {
  margin: 0 0 20px 0; }

.field {
  zoom: 1;
  max-width: 500px;
  padding: 4px 0 4px 0; }
  .field:before, .field:after {
    content: "";
    display: table; }
  .field:after {
    clear: both; }
  .field input[type=text], .field input[type=number], .field input[type=password] {
    border: 1px solid #ADADA2;
    height: 23px;
    padding: 4px;
    width: 100%;
    border-radius: 3px; }
  .field .fieldname, .field .inputwrap {
    width: 50%;
    float: left; }
  .field .inputwrap {
    padding-right: 4px; }
  .field .twofield {
    width: 50%;
    float: left; }
  .field li label {
    padding: 3px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.alert, .success {
  margin: 20px 0 20px 0;
  padding: 10px;
  color: #FFF; }
  .alert ul li, .success ul li {
    padding: 5px 10px 5px 10px; }

.alert {
  background-color: #E81A57; }

.success {
  background-color: #46C386; }
