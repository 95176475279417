html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

html[xmlns] .clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    position: relative;
    cursor: default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-ligatures: common-ligatures;
    -webkit-font-variant-ligatures: common-ligatures;

    /* for iOS and Safari 6 */
    font-variant-ligatures: common-ligatures;

    /* for up-to-date browsers, including IE10 and Opera 21 */
    text-rendering: optimizeLegibility;

    /* optional: for older browsers */
    -moz-font-feature-settings: "kern=1";

    /* pre-Firefox 14+ */
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";

    /* Firefox 14+ */
    font-feature-settings: "kern";

    /* standard */
    font-kerning: normal;

    /* Safari 7+, Firefox 24+, Chrome 33(?)+, Opera 21+*/
}

a:hover {
    cursor: pointer;
}

a:focus {
    outline: none;
}

img,
object,
embed {
    max-width: 100%;
    height: auto;
}

object,
embed {
    height: 100%;
}

img {
    -ms-interpolation-mode: bicubic;
}

img {
    display: inline-block;
    vertical-align: middle;
}

textarea {
    height: auto;
    min-height: 50px;
}

select {
    width: 100%;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    outline: none;
}

ul, li, ol {
    list-style-type: none;
}

.hidden {
    display: none !important;
}

strong {
    font-weight: bold;
}

input[type="text"] {
    -webkit-appearance: none;
    border-radius: 0;
}