.powertable {

	margin-bottom: 20px;

	.pagination {

		width: 100%;

		font-size: 13px;
		text-transform: lowercase;
		font-weight: 300;

		margin: $base-line-height 0 0 0;

		@extend %clearfix;

		li a {

			display: block;
			text-transform: lowercase;

		}

		li a[rel="next"] {
			float: right;
			&:after {
				content: " »";
			}
		}
		li a[rel="prev"] {
			float: left;
			&:before {
				content: "« ";
			}
		}
	}

	.list_top {

		@extend %clearfix;

	}

	.list_header {

		width: 100%;

		@extend %clearfix;

		h1 {

			line-height: 45px;
			width: 100%;

		}

		.pages {

			display: block;
			width: 50%;
			float: left;

		}

		.table_menu {

			display: block;
			width: 50%;
			float: right;

			li {

				float: right;
				display: block;
				margin: 0 0 0 10px;

			}

		}

	}

	table.maintable {

		width: 100%;
		margin: $base-line-height 0 0 0;

	}

	tbody {

		font-size: 13px;
		line-height: 15px;

		p {
			margin: 0 0 0 0;
			font-weight: 300;
		}

		.cellid {

			position: absolute;
			top: 2px;
			left: 0px;
			display: block;
			}

		.celldata {
			width: 100%;
			height: 100%;
			position: relative;
			padding: 0 0 0 20px;

		}

		strong {
			font-weight: 500;
		}

		i {
			font-weight: 400;
			font-style: italic;
		}

		p {
			line-height: 14px;
		}

		.datalist {

			p {
				font-size: 11px;
				line-height: 14px;
				margin: 0 0 0 0;
			}

		}


		td {

			padding: 6px;
			font-weight: 300;

		}

		th {
			padding: 6px;
			text-align: left;
			font-weight: 700;
		}

		tr {

			border-bottom: 1px dashed $color-content;

			&:last-child {

				border-bottom: none;

			}

			&:hover {

				background-color: #f7f7f7;

			}
		}

	}

	thead,tfoot {

		font-size: 13px;
		background-color: #f7f7f7;
		font-weight: 500;
		margin: 0 0 20px 0;
		text-align: left;

		tr {


			th {

				padding: 10px 13px 10px 6px;

				a {
					display: block;
					position: relative;
					width: 100%;
					height: 100%;

				}



			}

		}

	}

}