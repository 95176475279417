$width-field : 500px;
$color-input-border: #ADADA2;
$padding-global: 4px;
$border-radius: 3px;
$color-submit: $color-textlink;
$color-delete: #E81A57;


%button {

	height: 23px;
	padding: $padding-global;
	width: auto;
	border-radius: $border-radius;
	background-color: transparent;
	cursor: pointer;
}

%button-style-normal {

	border: 1px solid $color-submit;
	color: $color-submit;

	&:hover {

		background-color: $color-submit;
		color: #FFF;

	}

}

%button-style-delete {

	border: 1px solid $color-delete;
	color: $color-delete;

	&:hover {

		background-color: $color-delete;
		color: #FFF;

	}
}

form {
	margin: 0 0 20px 0;
	input[type=submit] {

		@extend %button;
		@extend %button-style-normal;

		&.delete {

			@extend %button-style-delete;

		}

	}

}

.field {

	@include clearfix();
	max-width: $width-field;
	padding: $padding-global 0 $padding-global 0;

	input[type=text],input[type=number],input[type=password] {

		border: 1px solid $color-input-border;
		height: 23px;
		padding: $padding-global;
		width: 100%;
		border-radius: $border-radius;

	}

	.fieldname, .inputwrap {

		width: 50%;
		float: left;

	}

	.inputwrap {
		padding-right: $padding-global;
	}

	.twofield {

		width: 50%;
		float: left;

		.fieldname {

			@extend %size-small;

		}

	}
	li {
		label {
			padding: 3px;
			@extend %size-small;
			cursor: pointer;
			@include user-select(none);
		}
	}

}