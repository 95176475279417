%size-small {
	font-size: 12px;
	line-height: 15px;
}
%size-normal {
	font-size: 14px;
	line-height: 18px;
}


* {
	font-family: "Open Sans", Helvetica, Arial;
}



h1, %size-huge {

	font-size: 33px;
	line-height: 40px;
	margin-bottom: 33px;

}

a {
	color: $color-textlink;
	&:hover {
		color: $color-textlink-hover;
	}
}